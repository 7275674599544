import { RdvCenter } from 'models/appointment.model';
import { CenterInfo } from 'pages/patient/centers/types';
import { FertilityCenterAction, FertilityCenterActionType } from 'store/actions/fertilityCenter.actions';

type FertilityCenterInitialState = {
  center: CenterInfo | null;
  rdvCenter: RdvCenter | null;
};

const navMenuInitialState: FertilityCenterInitialState = {
  center: null,
  rdvCenter: null,
};

export default function fertilityCenterReducer(prevState = navMenuInitialState, action: FertilityCenterAction) {
  switch (action.type) {
    case FertilityCenterActionType.ADD:
      return {
        ...prevState,
        center: action.center,
        rdvCenter: action.rdvCenter,
      };

    default:
      return prevState;
  }
}
