import { RdvCenter } from 'models/appointment.model';
import { CenterInfo } from 'pages/patient/centers/types';

export enum FertilityCenterActionType {
  ADD = 'FERTILITY_CENTER_ADD',
}

export type FertilityCenterAction = {
  type: FertilityCenterActionType.ADD;
  center: CenterInfo | null;
  rdvCenter: RdvCenter | null;
};
