import { Button, IconButton, Stack, styled, SvgIcon, ToggleButton, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as PlusSquareIcon } from 'resources/icons/plus_square.svg';
import { ReactComponent as deleteIcon } from 'resources/icons/delete.svg';
import CheckMark from 'resources/icons/checkmark.svg';
import AddPatientModal from './AddPatientModal';
import { PatientType, Patient } from './types/Patient';
import { useTranslation } from 'react-i18next';
import { useAuthenticatedUser, useCurrentUserRelatives } from 'hooks';
import { useSelector, useStore } from 'react-redux';
import { NewAppointmentFlowActionType } from 'store/actions/newAppointmentFlow.actions';
import { Gender } from 'models/profile.model';
import { AppointmentFlowState } from 'store/reducers/newAppointmentFlow.reducer';
import { GlobalState } from 'store/reducers';
import { redesignColors } from 'resources/theme/theme.colors';

interface ChoosePatientsProps {
  setNextButtonActive: (state: boolean) => void;
}

const stackStyles = { width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', mt: 0 };

const buttonStyles = {
  mt: 0,
  border: '1px solid #7A9099',
  borderRadius: '8px',
  padding: '12px 32px 12px 32px',
};

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  width: '100%',
  height: '44px',
  ...buttonStyles,
  [`&.Mui-selected`]: {
    border: 'none',
    background: '98% 50% no-repeat url("' + CheckMark + '"), ' + theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const ChoosePatients: FC<ChoosePatientsProps> = ({ setNextButtonActive }) => {
  const authenticatedUser = useAuthenticatedUser();
  const relatives = useCurrentUserRelatives();

  const { dispatch } = useStore();
  const patients = useSelector((state: GlobalState) => (state.newAppointmentFlowReducer as AppointmentFlowState).patients);

  const { t } = useTranslation();

  const mapGender = (gender: Gender) => {
    switch (gender) {
      case Gender.MALE:
        return 'male';
      case Gender.FEMALE:
        return 'female';
      case Gender.MALE_FULL:
        return 'male';
      case Gender.FEMALE_FULL:
        return 'female';
      case Gender.MALE_V4:
        return 'male';
      case Gender.FEMALE_V4:
        return 'female';
    }
  };

  useEffect(() => {
    if (authenticatedUser) {
      if (relatives?.length) {
        const updatedRelatives = relatives.filter((relative) => !patients.find((patient) => patient.id === relative.id));
        updatedRelatives.forEach((relative, index) => {
          dispatch({
            type: NewAppointmentFlowActionType.SET_PATIENT_INFO,
            patientInfo: {
              key: index,
              type: PatientType.Adult,
              firstName: relative.first_name,
              lastName: relative.last_name,
              cns: relative.cns,
              birthDate: new Date(relative.birth_date),
              email: relative.email,
              phone: relative.phone,
              selected: false,
              gender: relative.gender,
              id: relative.id,
              canRemove: false,
            },
          });
        });
      } else {
        const emptyPatient: Patient = {
          key: 0,
          type: PatientType.Adult,
          isCurrentUser: true,
          firstName: authenticatedUser.first_name,
          lastName: authenticatedUser.last_name,
          birthName: authenticatedUser.birth_name,
          cns: authenticatedUser.cns,
          birthDate: new Date(authenticatedUser.birth_date),
          email: authenticatedUser.email,
          phone: authenticatedUser.phone,
          selected: false,
          id: authenticatedUser.id,
          canRemove: false,
          gender: authenticatedUser.gender,
        };
        dispatch({ type: NewAppointmentFlowActionType.SET_PATIENT_INFO, patientInfo: emptyPatient });
      }
    } else {
      const emptyAdult: Patient = {
        key: 0,
        type: PatientType.Adult,
        isCurrentUser: true,
        selected: false,
        id: 'adult0',
        canRemove: true,
        firstName: '',
        lastName: '',
        birthName: '',
        email: '',
        phone: '',
        gender: Gender.MALE,
      };
      dispatch({ type: NewAppointmentFlowActionType.SET_PATIENT_INFO, patientInfo: emptyAdult });
    }
  }, [authenticatedUser, relatives, patients]);

  useEffect(() => {
    if (patients.length > 0 && patients.some((patient) => patient.selected)) {
      setNextButtonActive(true);
    } else {
      setNextButtonActive(false);
    }

    const adultsNumber = patients.filter((patient) => patient.type === PatientType.Adult && patient.selected).length;
    const childrenNumber = patients.filter((patient) => patient.type === PatientType.Child && patient.selected).length;

    dispatch({
      type: NewAppointmentFlowActionType.SET_ADULTS_NUMBER,
      adultsNumber,
    });

    dispatch({
      type: NewAppointmentFlowActionType.SET_CHILDREN_NUMBER,
      childrenNumber,
    });
  }, [patients, setNextButtonActive, dispatch]);

  const [isModalOpen, setModalOpen] = useState(false);

  const handlePatientsChange = (id: string | number) => {
    const updatedPatient = patients.find((patient) => patient.id === id);

    if (updatedPatient) {
      dispatch({ type: NewAppointmentFlowActionType.SET_PATIENT_INFO, patientInfo: { ...updatedPatient, selected: !updatedPatient?.selected } });
    }
  };

  const handleAddPatient = (selected: PatientType) => {
    setModalOpen(false);

    dispatch({ type: NewAppointmentFlowActionType.SET_PATIENT_INFO, patientInfo: { key: patients.length, type: selected, selected: true, id: selected + patients.length, canRemove: true } });
  };

  const handleDeletePatient = (key: string | number) => {
    const deletedPatient = patients.find((patient) => patient.key === key);

    if (deletedPatient) {
      dispatch({
        type: NewAppointmentFlowActionType.REMOVE_PATIENT_INFO,
        id: deletedPatient?.id,
      });
    }
  };

  return (
    <>
      <Stack sx={stackStyles} spacing={2}>
        <Typography component='h2' variant='h2' sx={{ fontWeight: 600 }}>
          {t('checkin.stepper.steps.choose_patient.title')}
        </Typography>

        <Stack
          sx={{
            ...stackStyles,
            width: {
              xs: '100%',
              sm: '400px',
            },
          }}
          spacing={2}
        >
          {patients.map((patient) => (
            <Stack key={patient.key} sx={{ ...stackStyles, flexDirection: 'row', alignContent: 'center', gap: '16px' }}>
              <StyledToggleButton
                aria-label={`patient-toggle-${patient.key}`}
                key={patient.key}
                value={patient.type}
                selected={patient.selected}
                onChange={(e, v) => handlePatientsChange(patient.id)}
                sx={{ ...(!patient.selected && { background: redesignColors.white, borderRadius: '8px' }) }}
              >
                {patient?.firstName
                  ? `${patient?.firstName} ${patient?.lastName}`
                  : patient.type === PatientType.Adult
                  ? t('checkin.stepper.steps.choose_patient.adult')
                  : t('checkin.stepper.steps.choose_patient.child')}
              </StyledToggleButton>
              {patient.canRemove && (
                <IconButton sx={{ border: 'none', marginTop: 0, color: redesignColors.red }} onClick={() => handleDeletePatient(patient.key)}>
                  <SvgIcon component={deleteIcon} />
                </IconButton>
              )}
            </Stack>
          ))}
        </Stack>

        <Button sx={{ border: 'none', color: '#0F6F99' }} startIcon={<SvgIcon component={PlusSquareIcon} inheritViewBox />} onClick={() => setModalOpen(true)}>
          {t('checkin.stepper.steps.choose_patient.button.add_patient')}
        </Button>
      </Stack>

      <AddPatientModal open={isModalOpen} onClose={() => setModalOpen(false)} onAddPatient={handleAddPatient} />
    </>
  );
};

export default ChoosePatients;
