import { Grid } from '@mui/material';
import { FC } from 'react';
import Summary from 'components/summary';
import { CenterInfo } from 'pages/patient/centers/types';
import formatCenterAddress from 'pages/patient/centers/utils/formarCenterAddress';
import { AppointmentPatient, PatientFiles } from 'components/appointment/types/Patient';
import { AppointmentSlot } from 'models/appointment.slots.model';
import dayjs from 'dayjs';

const SummaryStep: FC<{
  handleNext: () => void;
  date: Date | null;
  centerInfo: CenterInfo | null;
  patient: AppointmentPatient | null;
  slot: AppointmentSlot | null;
  prescriptionFiles: PatientFiles[];
}> = ({ date, slot, prescriptionFiles, centerInfo, patient }) => {
  if (!centerInfo || !patient || !date || !slot) {
    return null;
  }
  return (
    <Grid xs={12} sm={8} md={6} item sx={{ margin: '0 auto' }}>
      <Summary
        summaryFields={{
          labTitle: centerInfo.center.attributes.title,
          labAddress: formatCenterAddress(centerInfo.center),
          procedure: 'Fertility',
          duration: '30 minutes',
          date,
          timeSlot: dayjs(slot.start).utc().format('HH:mm'),
          prescriptions: prescriptionFiles
            .map((prescription) => ({
              pacient: `${patient?.firstName} ${patient?.lastName || ''}`,
              doctor: 'Dr. Emily Smith',
              file: prescription.files.map((file) => file.name).join('\n'),
              validUntilDate: new Date(),
            }))
            .flat(1),
        }}
      />
    </Grid>
  );
};

export default SummaryStep;
