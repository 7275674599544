import { Gender } from 'models/profile.model';

export enum PatientType {
  Adult = 'Adult',
  Child = 'Child',
}

export interface Patient {
  key: number;
  type: PatientType;
  firstName: string;
  lastName: string;
  birthName: string;
  cns?: string;
  birthDate?: Date;
  email: string;
  emailConfirm?: string;
  phone: string;
  isCurrentUser?: boolean;
  selected?: boolean;
  id: string | number;
  canRemove?: boolean;
  gender: Gender;
}

export interface AppointmentPatient {
  id: string | number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  gender: Gender;
  birthDate?: Date;
  birthName: string;
  emailConfirm?: string;
  type?: PatientType;
  cns?: string;
  existed?: boolean;
  canRemove?: boolean;
  selected?: boolean;
}

export interface PatientFiles {
  patientId: string;
  files: File[];
}
