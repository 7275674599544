import { APIConfiguration, axiosGoogle, axiosMYLAB, axiosMYRDV } from 'api';
import { AppointmentMedicalAct, AppointmentMedicalAct2023 } from 'models/appointment.medical.act.model';
import { Appointment, AppointmentResponse, Rdv, RdvAnalyse, RdvCenter } from 'models/appointment.model';
import { AppointmentSlot, AppointmentSlots, AppointmentSlots2023 } from 'models/appointment.slots.model';
import { convertDate } from 'utils/func/Date.func';
import { BaseService } from './base.service';
import config from 'config';
import { Address, Prescription } from 'store/reducers/newAppointmentFlow.reducer';
import dayjs from 'dayjs';
import { getBase64, removeBase64Prefix } from 'utils/func/Base64.func';
import { AppointmentPatient } from 'components/appointment/types/Patient';

type CreateAppointmentParams = {
  medicalAct: AppointmentMedicalAct;
  prescriptions: Prescription[];
  patients: AppointmentPatient[];
  slot: AppointmentSlot | null;
  address?: Address | undefined;
  centerId?: number;
};

class AppointmentService extends BaseService {
  async getToken() {
    // Check if token is in sessionStorage
    const token = sessionStorage.getItem('myRdvToken');
    if (token) {
      return token;
    }

    // Get token from API
    const { data } = await axiosMYRDV.post(this.apiConfig.APPOINTMENTS.GET_TOKEN, {
      username: config.myRdvApiUser,
      password: config.myRdvApiPassword,
    });

    if (!data.token) {
      throw new Error('No token found');
    }

    // Save token in sessionStorage
    sessionStorage.setItem('myRdvToken', data.token);
    return data.token;
  }

  /**
   * @deprecated Use getMedicalActs() instead
   */
  async getMedicalActs2023() {
    const { data } = await axiosMYLAB.get<AppointmentMedicalAct2023[]>(this.apiConfig.APPOINTMENTS.GET_MEDICAL_ACTS_2023(this.getLang()));

    this.log(`getMedicalActs()`);

    return data;
  }

  async getMedicalActs(lang: string) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.get<AppointmentMedicalAct[]>(this.apiConfig.APPOINTMENTS.GET_MEDICAL_ACTS(lang), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    this.log(`getMedicalActs()`);

    return data;
  }

  /**
   * @deprecated Use getSlots() instead
   */
  async getSlots2023(dateFrom: Date, dateTo: Date, medicalActsIds: string[], addressZipCode: string, addressCountryIso: string) {
    const { data } = await axiosMYLAB.post<AppointmentSlots2023>(this.apiConfig.APPOINTMENTS.GET_SLOTS_2023(this.getLang()), {
      date_from: convertDate(dateFrom.toISOString(), false, 'yyyy-MM-dd'),
      date_to: convertDate(dateTo.toISOString(), false, 'yyyy-MM-dd'),
      medical_acts: medicalActsIds.map((id) => parseInt(id)),
      zip_code: addressZipCode,
      country_iso: addressCountryIso,
    });

    this.log(`getSlots()`);

    return data;
  }

  async getSlots(dateFrom: Date, dateTo: Date, lat: number, lon: number, medicalActId: number) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.post<AppointmentSlots>(
      this.apiConfig.APPOINTMENTS.GET_SLOTS,
      {
        loc: [lon, lat],
        // Format date correctly with timezone offset
        start: new Date(dateFrom.getTime() - dateFrom.getTimezoneOffset() * 60000).toISOString(),
        end: new Date(dateTo.getTime() - dateTo.getTimezoneOffset() * 60000).toISOString(),
        analyses: [medicalActId],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    this.log(`getSlots()`);

    return data;
  }

  async getAddresses(address: string) {
    const key = 'AIzaSyCzJzcwOywUR-ckXi57zIwwLQf5uBWFYKA';
    const { data } = await axiosGoogle.get<AppointmentSlots>(this.apiConfig.GOOGLE.PLACE_AUTOCOMPLETE, {
      params: {
        input: address,
        key,
        components: 'country:LU',
      },
      headers: {
        accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    });

    this.log(`getAddresses()`);

    return data;
  }

  /**
   * @deprecated Use bookAppointment() instead
   */
  async bookAppointment2023(appointment: Appointment) {
    const { data } = await axiosMYLAB.post(this.apiConfig.APPOINTMENTS.POST_APPOINTMENT_2023(this.getLang()), appointment);

    this.log(`bookAppointment()`);

    return data;
  }

  async bookAppointment(rdv: Rdv, locale: string) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.post(
      this.apiConfig.APPOINTMENTS.POST_APPOINTMENT,
      {
        ...rdv,
        locale,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    this.log(`bookAppointment()`);

    return data;
  }

  async createAppointment({ address: fullAddress, prescriptions, patients, slot, medicalAct, centerId }: CreateAppointmentParams) {
    const token = await this.getToken();
    const { longLat, address } = fullAddress || {};

    const selectedPatients = centerId ? patients : patients.filter((patient) => patient?.selected);
    const loadedPrescriptions = await Promise.all(
      selectedPatients.map(async ({ birthDate, ...patient }) => {
        const patientPrescriptions = prescriptions.filter((prescr) => prescr.patientId == patient.id);
        const files = patientPrescriptions.reduce((acc, cur) => (cur?.files ? [...acc, ...cur?.files] : acc), [] as File[]);
        const ordonnances = await Promise.all(
          files.map(async (file) => ({
            file_name: file.name,
            file_type: `application/${file.name.split('.').pop()}`,
            file_content: removeBase64Prefix(await getBase64(file)),
          })),
        );
        const patientId = !patient?.id || typeof patient.id === 'string' ? '' : patient?.id;

        const birthdate = birthDate instanceof Date ? new Date(Date.UTC(birthDate.getFullYear(), birthDate.getMonth(), birthDate.getDate(), 0, 0, 0)).toISOString() : '';
        return {
          id: patientId,
          patient: {
            id: patientId,
            gender: patient.gender,
            firstname: patient.firstName,
            maritalName: patient.lastName,
            birthname: patient.birthName,
            birthdate,
            email: patient.email,
            verifyemail: patient.emailConfirm,
            phone: patient.phone,
            cns: patient?.cns || '',
            marital_name: '',
            addresses: [],
            health_fund_id: '',
            mobile_phone_is_relative: false,
            mylab_refused: false,
            is_active: true,
            disputes: false,
          },
          analyses: [
            {
              ...medicalAct,
              startHour: dayjs(slot?.start)
                .utc()
                .format('HH:mm:ss'),
              endHour: dayjs(slot?.end)
                .utc()
                .format('HH:mm:ss'),
            },
          ],
          ordonnances,
        };
      }),
    );

    const coordinates =
      address && longLat
        ? {
            coordInfo: {
              lat: longLat?.lat,
              lon: longLat?.long,
              display_name: `${address?.number} ${address?.street}, ${address?.zip_code} ${address?.city}, ${address?.country}`,
              country: address?.country || 'Luxembourg',
              country_code: address?.country_iso,
              postcode: address?.zip_code,
              city: address?.city,
              state: address?.country || 'Luxembourg',
              street_number: address?.number || '',
              street_name: address?.street || '',
              comment: '',
            },
          }
        : {};
    const { data } = await axiosMYRDV.post(
      this.apiConfig.APPOINTMENTS.POST_APPOINTMENT,
      {
        ...coordinates,
        slot: slot?.start,
        center: centerId ? { id: centerId } : null,
        prescriptions: loadedPrescriptions,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    this.log(`createAppointment()`);

    return data;
  }

  async getCentersFromActs(medical_acts: RdvAnalyse[]) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.post(
      `${this.apiConfig.APPOINTMENTS.GET_NEAREST_CENTERS}?nextSlot=true`,
      {
        medical_acts,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    this.log(`getCentersFromActs()`);

    return data;
  }

  async getNearestCenters(lat: number, lon: number, medical_acts: RdvAnalyse[], radius?: number) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.post(
      `${this.apiConfig.APPOINTMENTS.GET_NEAREST_CENTERS}?nextSlot=true`,
      {
        coord: [lon, lat],
        radius,
        medical_acts,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    this.log(`getNearestCenters()`);

    return data;
  }

  async getCenterSlots(centerId: number | string, start: Date, end: Date, medical_acts: { id: number }[]) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.post<AppointmentSlots>(
      this.apiConfig.APPOINTMENTS.GET_CENTER_SLOTS,
      {
        centerId: centerId,
        start: new Date(start.getTime() - start.getTimezoneOffset() * 60000).toISOString(),
        end: new Date(end.getTime() - end.getTimezoneOffset() * 60000).toISOString(),
        medical_acts,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    this.log(`getCenterSlots()`);

    return data;
  }

  async getAppointmentsByToken(cancelToken: string) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.get(this.apiConfig.APPOINTMENTS.GET_APPOINTMENTS_BY_TOKEN(cancelToken), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    this.log(`getAppointmentsByToken()`);

    return data;
  }

  async getAppointments(patientId: number): Promise<AppointmentResponse[]> {
    const { data } = await axiosMYLAB.get(this.apiConfig.APPOINTMENTS.GET_APPOINTMENTS(this.getLang()), {
      params: {
        patient_id: patientId,
      },
    });

    this.log(`getAppointments()`);

    return data;
  }
  async getCenters(): Promise<RdvCenter[]> {
    const token = await this.getToken();

    const { data } = await axiosMYRDV.get(this.apiConfig.APPOINTMENTS.GET_RDV_CENTERS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    this.log(`getCenters()`);

    return data;
  }

  async getAppointment(appointId: string): Promise<AppointmentResponse[]> {
    const { data } = await axiosMYLAB.get(this.apiConfig.APPOINTMENTS.GET_APPOINTMENT(this.getLang(), appointId), {});

    this.log(`getAppointment()`);

    return data;
  }

  async cancelAppointment(id: number) {
    const token = await this.getToken();
    const { data } = await axiosMYRDV.post(
      this.apiConfig.APPOINTMENTS.CANCEL_APPOINTMENT,
      {
        appointIds: [id],
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    this.log(`cancelAppointment(${id})`);

    return data;
  }

  /**
   * @deprecated Use cancelAppointment() instead
   */
  async cancelAppointment2023(id: number) {
    await axiosMYLAB.delete(this.apiConfig.APPOINTMENTS.CANCEL_APPOINTMENT_2023(this.getLang(), id));

    this.log(`cancelAppointment(${id})`);
  }
}

export default (apiConfig: APIConfiguration) => {
  return new AppointmentService(apiConfig);
};
