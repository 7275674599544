import { Alert, Grid, SvgIcon } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PatientInfoCard from 'pages/patient/new-appointment/components/PatientInfoCard';
import { redesignColors } from 'resources/theme/theme.colors';
import { ReactComponent as InfoIcon } from 'resources/icons/info.svg';
import PatientFilledCard from 'pages/shared/fertility/components/PatientFilledCard';
import { AppointmentPatient } from 'components/appointment/types/Patient';

const InformationStep: FC<{
  handleIsActiveNextButton: () => void;
  setPatient: (data: AppointmentPatient) => void;
  patient: AppointmentPatient | null;
}> = ({ handleIsActiveNextButton, setPatient, patient }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState(patient?.email || '');
  const [emailConfirm, setEmailConfirm] = useState(patient?.email || '');
  const [phone, setPhone] = useState(patient?.phone || '');

  useEffect(() => {
    const allFieldIsValid = patient?.existed
      ? phone && emailConfirm && email && emailConfirm === email
      : patient?.birthName && patient?.gender && patient?.firstName && patient?.phone && patient?.emailConfirm && patient?.email && patient?.emailConfirm === patient?.email && patient?.birthDate;
    if (allFieldIsValid) {
      handleIsActiveNextButton();
    }
  }, [patient, phone, email, emailConfirm]);

  useEffect(() => {
    setPatient({ ...(patient || {}), gender: 'male' } as AppointmentPatient);
  }, []);

  return (
    <Grid item xs={12} sm={8} lg={7} sx={{ margin: '0 auto', mt: '8px' }}>
      <Alert severity='info' sx={{ backgroundColor: redesignColors.blue.blue3, fontSize: '14px', fontWeight: 500, borderRadius: '8px', width: '100%', mb: 2 }} icon={<SvgIcon component={InfoIcon} />}>
        {t('fertility.opening.male_only')}
      </Alert>
      {patient?.existed ? (
        <PatientFilledCard patient={patient} email={email} setEmail={setEmail} phone={phone} setPhone={setPhone} emailConfirm={emailConfirm} setEmailConfirm={setEmailConfirm} />
      ) : (
        <PatientInfoCard
          type='Patient'
          onlyMale
          values={patient || undefined}
          disableStorage
          setValue={(key: string, value: string | Date) => setPatient({ ...(patient || {}), [key]: value } as AppointmentPatient)}
        />
      )}
    </Grid>
  );
};

export default InformationStep;
