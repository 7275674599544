import { Stack } from '@mui/material';
import Summary, { SummaryPrescription } from 'components/summary';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useStore } from 'react-redux';
import { AppointmentFlowState } from 'store/reducers/newAppointmentFlow.reducer';

const SummaryStep: FC = () => {
  const { getState } = useStore();
  const state: AppointmentFlowState = getState().newAppointmentFlowReducer;
  const {
    address: { address },
  } = state;

  return (
    <Stack spacing={2}>
      <Summary
        summaryFields={{
          labTitle: `${address?.company ? `${address?.company},` : ''} ${address?.number}, ${address?.street} `,
          labAddress: state.address.additionalInfo,
          procedure: 'Sampling',
          duration: '30 minutes',
          date: dayjs(state.slot?.start)
            .utc()
            .toDate(),
          timeSlot: dayjs(state.slot?.start)
            .utc()
            .format('HH:mm'),
          prescriptions: state.patients.reduce(
            (acc, patient) =>
              patient.selected
                ? [
                    ...acc,
                    {
                      pacient: `${patient.firstName} ${patient.lastName}`,
                      doctor: '',
                      file: state.prescriptions
                        ? state.prescriptions
                            .filter((p) => p.patientId === patient.id)[0]
                            ?.files.map((f) => f.name)
                            .join(', ')
                        : '',
                      validUntilDate: new Date(),
                    },
                  ]
                : acc,
            [] as SummaryPrescription[],
          ),
        }}
      />
    </Stack>
  );
};

export default SummaryStep;
