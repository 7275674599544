import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useIsMobileView } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CustomDateCalendar from 'components/custom-date-calendar';
import TimeSlots from 'components/slot-picker';
import dayjs from 'dayjs';
import api from 'api';
import { AppointmentSlot, AppointmentSlots } from 'models/appointment.slots.model';
import { getDisabledSlots, shouldDisableDate } from 'pages/patient/new-appointment/AvailableSlotStep';
import { RdvCenter } from 'models/appointment.model';

type AvailaleSlotType = {
  handleIsActiveNextButton: (isActive?: boolean) => void;
  setDate: (date: Date | null) => void;
  rdvCenter?: RdvCenter | null;
  date: Date | null;
  setShowSlots: (showSlots: boolean) => void;
  setDaySlot: (showSlots: AppointmentSlot | null) => void;
  showSlots: boolean;
  medicalActId?: number;
  daySlot: AppointmentSlot | null;
};

const AvailaleSlot: FC<AvailaleSlotType> = ({ handleIsActiveNextButton, date, setDate, rdvCenter, showSlots, setShowSlots, setDaySlot, daySlot, medicalActId }) => {
  const { t } = useTranslation();
  const [slots, setSlots] = useState<AppointmentSlots | null>(null);
  const [daySlots, setDaySlots] = useState<AppointmentSlot[]>([]);
  const [slot, setSlot] = useState<string>(daySlot ? dayjs(daySlot.start).utc().format('HH:mm') : '');

  const [loading, setLoading] = useState<boolean>(true);

  const isMobileView = useIsMobileView();

  useEffect(() => {
    if (medicalActId && rdvCenter?.id) {
      setLoading(true);
      api.appointments
        .getCenterSlots(rdvCenter?.id, new Date(), dayjs().add(30, 'day').toDate(), [{ id: medicalActId }])
        .then((res) => {
          setSlots(res);
        })
        .finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    if (showSlots && daySlot) {
      handleIsActiveNextButton();
    }
  }, [showSlots, daySlot]);

  useEffect(() => {
    if (date && !daySlots.length) {
      const slotKey = dayjs(date).utc().format('YYYY-MM-DD');
      const dateSlots = ((slots && slots[slotKey]) || []) as AppointmentSlot[];
      setDaySlots(dateSlots);
    }
  }, [date, daySlots]);

  return (
    <Grid item xs={12} mb='10%'>
      {loading ? (
        <Box display='flex' justifyContent='center' alignItems='center' flex={1} mt='15%'>
          <CircularProgress size={50} />
        </Box>
      ) : showSlots && date ? (
        <TimeSlots
          sx={{ width: isMobileView ? '220px' : '470px !important', margin: '0 auto' }}
          title={t('fertility.available_slot.avalible_slots')}
          selectedSlot={slot}
          disabledSlots={getDisabledSlots(daySlots)}
          setSelectedSlot={(slot) => {
            const newDaySlot = daySlots.find((dSlot) => dayjs(dSlot.start).utc().format('HH:mm') === slot);
            if (newDaySlot) {
              setSlot(slot);
              setDaySlot(newDaySlot);
              handleIsActiveNextButton();
            }
          }}
          date={date}
          handleBack={() => setShowSlots(false)}
        />
      ) : (
        <>
          <Typography sx={{}} fontSize='20px' fontWeight='600' textAlign='center'>
            {t('fertility.available_slot.select_date')}
          </Typography>
          <CustomDateCalendar
            value={date}
            onChange={(newDate) => {
              setDate(newDate);
              const currentDate = dayjs(newDate);
              const slotKey = currentDate.utc().format('YYYY-MM-DD');
              const dateSlots = ((slots && slots[slotKey]) || []) as AppointmentSlot[];

              if (!dateSlots?.length && medicalActId && rdvCenter?.id) {
                setLoading(true);
                api.appointments
                  .getCenterSlots(rdvCenter?.id, currentDate.toDate(), currentDate.add(1, 'day').toDate(), [{ id: medicalActId }])
                  .then((res) => {
                    setSlots((prev) => ({ ...prev, ...res }));
                    const newSlots = { ...slots, ...res };
                    const dateSlots = (newSlots ? newSlots[slotKey] : []) as AppointmentSlot[];
                    if (!dateSlots.length) {
                      return;
                    }
                    setDaySlots(dateSlots);
                    setShowSlots(true);
                  })
                  .finally(() => setLoading(false));

                return;
              }
              setDaySlots(dateSlots);

              handleIsActiveNextButton();
            }}
            minDate={new Date()}
            shouldDisableDate={(date) => shouldDisableDate(date, slots)}
            loading={false}
          />
        </>
      )}
    </Grid>
  );
};

export default AvailaleSlot;
