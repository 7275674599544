export const dialogs: Record<string, { title: string; subtitle: string; submitTitle: string; cancelTitle?: string; hideCancel?: boolean }> = {
  delete: {
    title: 'fertility.cancel_dialog.title',
    subtitle: 'fertility.cancel_dialog.subtitle',
    submitTitle: 'common.action.delete',
    hideCancel: false,
  },
  error_submit: {
    title: 'fertility.error_submit_dialog.title',
    subtitle: 'fertility.error_submit_dialog.subtitle',
    submitTitle: 'common.action.understood',
    hideCancel: true,
  },
  not_available_slot: {
    title: 'fertility.not_available_slot_dialog.title',
    subtitle: 'fertility.not_available_slot_dialog.subtitle',
    submitTitle: 'fertility.not_available_slot_dialog.submit_title',
    cancelTitle: 'common.action.close',
  },
  success_submit: {
    title: 'fertility.success_submit_dialog.title',
    subtitle: 'fertility.success_submit_dialog.subtitle',
    submitTitle: 'common.action.understood',
    hideCancel: true,
  },
};
