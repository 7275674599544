import { Alert, Box, Breadcrumbs, Button, Grid, StepConnector, stepConnectorClasses, StepLabel, stepLabelClasses, stepIconClasses, Stepper, styled, SvgIcon, Typography, Step } from '@mui/material';
import BodyTemplate from 'components/_layout/navigation/BodyTemplate';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthenticated, useContextRedirection, useIsMobileView, useNotification } from 'hooks';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConnectAccountDialog from 'components/connect-account-dialog';
import { ReactComponent as InfoIcon } from 'resources/icons/info.svg';
import RoutePaths from 'utils/RoutePaths';
import { redesignColors } from 'resources/theme/theme.colors';
import SelectFlowStep from './steps/SelectFlowStep';
import AddressStep from './steps/AddressStep';
import AvailaleSlot from './steps/AvailableSlotStep';
import InformationStep from './steps/InformationStep';
import SummaryStep from './steps/SummaryStep';
import ConfirmDialog from 'components/confirm-dialog';
import PrescriptionsStep from './steps/PrescriptionsStep';
import IntroductionStep from './steps/IntroductionStep';
import { AnalysisType, FertitlityStep, FertilityStepType } from './types';
import { AppointmentPatient, PatientFiles } from 'components/appointment/types/Patient';
import { useFertilityCenter } from 'hooks/useFertilityCenter';
import { useMedicalActs } from 'hooks/useMedicalActs';
import { FERTILITY_DIAGNOSTIC_TEST_CODE, FERTILITY_INSIMINATION_CODE } from 'utils/Constants';
import api from 'api';
import { AppointmentSlot } from 'models/appointment.slots.model';
import { dialogs } from './constants';

enum DialogType {
  delete = 'delete',
  error_submit = 'error_submit',
  not_available_slot = 'not_available_slot',
  success_submit = 'success_submit',
}

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  [`&.${stepLabelClasses.root}`]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${stepLabelClasses.iconContainer}`]: {
    padding: 0,
  },
  [`& .${stepLabelClasses.iconContainer}.Mui-completed`]: {
    background: theme.palette.common.black,
    borderRadius: '50%',
  },
  [`& .${stepLabelClasses.labelContainer}`]: {
    position: 'absolute',
    width: 'auto',
    transform: 'translateY(130%)',
  },
  [`& .${stepIconClasses.root}`]: {
    borderWidth: '1px',
  },
  [`& .${stepIconClasses.root}.Mui-completed`]: {
    borderWidth: '0px !important',
  },
  [`& .${stepIconClasses.root}.Mui-completed path`]: {
    borderWidth: 1,
  },
  [`& .${stepIconClasses.text}`]: {
    fontSize: '14px',
    fontWeight: '400',
  },
}));

const CustomStepperConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    position: 'relative',
    top: 11,
    borderWidth: 0.5,
    margin: '0 3%',
  },
}));

const Fertility: FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();
  const navigate = useContextRedirection();
  const isMobileView = useIsMobileView();
  const isAuthenticated = useAuthenticated();
  const [isActiveNextButton, setIsActiveNextButton] = useState(false);

  const { center: centerInfo, rdvCenter } = useFertilityCenter();
  const { notification } = useNotification();
  const [date, setDate] = useState<Date | null>(null);
  const [prescriptionFiles, setPrescriptionsFiles] = useState<PatientFiles[]>([]);
  const [showSlots, setShowSlots] = useState<boolean>(false);
  const [daySlot, setDaySlot] = useState<AppointmentSlot | null>(null);
  const { medicalActs } = useMedicalActs();

  const [fertilityType, setFertilityType] = useState<AnalysisType | null>(null);
  const [patient, setPatient] = useState<AppointmentPatient | null>(null);

  const [isOpeningPage, setIsOpeningPage] = useState(true);
  const [showInfoPage, setShowInfoPage] = useState(true);
  const [openDialogType, setOpenDialogType] = useState<DialogType | null>(null);

  const handleNext = () => {
    setActiveStep((activeStep) => (activeStep >= steps.length - 1 ? activeStep : activeStep + 1));
  };

  const handleBack = () => {
    setActiveStep((activeStep) => activeStep - 1);
  };
  const handleIsActiveNextButton = (isActive?: boolean) => {
    setIsActiveNextButton(isActive || true);
  };

  const steps: FertitlityStep[] = useMemo(() => {
    const navigationProps = { handleNext, handleBack, handleIsActiveNextButton };

    const medicalActCode = fertilityType === AnalysisType.test ? FERTILITY_DIAGNOSTIC_TEST_CODE : FERTILITY_INSIMINATION_CODE;
    const medicalActId = medicalActs.find((act) => act.code === medicalActCode)?.id;
    const stepper = [
      ...(isAuthenticated
        ? [
            {
              title: t('fertility.introduction.title'),
              type: FertilityStepType.introduction,
              component: <IntroductionStep {...navigationProps} setPatient={setPatient} />,
            },
          ]
        : []),
      {
        title: t('fertility.address.title'),
        type: FertilityStepType.address,
        component: <AddressStep {...navigationProps} centerInfo={centerInfo} />,
      },
      ...(fertilityType === AnalysisType.insimination
        ? [
            {
              title: t('fertility.information.title'),
              type: FertilityStepType.information,
              component: <InformationStep {...navigationProps} setPatient={setPatient} patient={patient} />,
              nextButtonLabel: 'common.action.continue',
            },
          ]
        : [
            {
              title: t('fertility.available_slot.title'),
              type: FertilityStepType.availableSlot,
              component: (
                <AvailaleSlot
                  {...navigationProps}
                  setDate={setDate}
                  date={date}
                  rdvCenter={rdvCenter}
                  showSlots={showSlots}
                  setShowSlots={setShowSlots}
                  daySlot={daySlot}
                  setDaySlot={setDaySlot}
                  medicalActId={medicalActId}
                />
              ),
            },
          ]),
      ...(fertilityType === AnalysisType.insimination
        ? [
            {
              title: t('fertility.available_slot.select_date'),
              type: FertilityStepType.availableSlot,
              component: (
                <AvailaleSlot
                  {...navigationProps}
                  setDate={setDate}
                  date={date}
                  rdvCenter={rdvCenter}
                  showSlots={showSlots}
                  setShowSlots={setShowSlots}
                  daySlot={daySlot}
                  setDaySlot={setDaySlot}
                  medicalActId={medicalActId}
                />
              ),
            },
          ]
        : [
            {
              title: t('fertility.information.title'),
              type: FertilityStepType.information,
              component: <InformationStep {...navigationProps} setPatient={setPatient} patient={patient} />,
              nextButtonLabel: 'common.action.continue',
            },
          ]),
      {
        title: t('fertility.prescriptions.title'),
        type: FertilityStepType.prescriptions,
        component: (
          <PrescriptionsStep
            {...navigationProps}
            setPrescriptionsFiles={setPrescriptionsFiles}
            prescriptionFiles={prescriptionFiles}
            patient={patient}
            setNextButtonActive={() => setIsActiveNextButton(true)}
          />
        ),
        nextButtonLabel: 'common.action.continue',
      },
      {
        title: t('fertility.summary.title'),
        type: FertilityStepType.summary,
        component: <SummaryStep {...navigationProps} date={date} centerInfo={centerInfo} prescriptionFiles={prescriptionFiles} slot={daySlot} patient={patient} />,
        nextButtonLabel: 'common.action.confirm',
      },
    ];

    return stepper;
  }, [t, centerInfo, patient, date, fertilityType, showSlots, prescriptionFiles, daySlot, medicalActs]);

  const isLastStep = activeStep === steps.length - 1;
  const nextAndBackContainerProps = [3, 4, 5].includes(activeStep) ? { xs: 12, sm: 8, lg: 7, sx: { margin: '0 auto', mt: '8px' } } : { xs: 12, sm: 8 };

  const handleCreateAppointment = async () => {
    const medicalActCode = fertilityType === AnalysisType.test ? FERTILITY_DIAGNOSTIC_TEST_CODE : FERTILITY_INSIMINATION_CODE;
    const medicalAct = medicalActs.find((act) => act.code === medicalActCode);
    if (!medicalAct || !rdvCenter?.id || !patient) {
      notification(t('patient_details.notification.error'), 'error');
      return;
    }

    api.appointments
      .createAppointment({ prescriptions: prescriptionFiles, patients: [patient], slot: daySlot, medicalAct, centerId: rdvCenter.id })
      .then(() => setOpenDialogType(DialogType.success_submit))
      .catch((error) => {
        if (error?.response?.data?.code === 'SLOT_NOT_AVAILABLE') {
          setOpenDialogType(DialogType.not_available_slot);
          return;
        }
        setOpenDialogType(DialogType.error_submit);
      });
  };

  return (
    <BodyTemplate>
      <Grid container justifyContent='center'>
        <Grid item xs={12} mb={2} position='relative' sx={{ paddingTop: '0px !important' }}>
          {!isOpeningPage && (
            <Stepper
              activeStep={activeStep}
              sx={{
                alignItems: 'flex-start',
                width: {
                  xs: '100%',
                  sm: '100%',
                  md: '95%',
                },
                mb: 4,
                '.MuiStepLabel-labelContainer span': {
                  fontSize: '16px',
                  transform: 'translateX(40%)',
                },
                '.MuiStepLabel-labelContainer .Mui-active': {
                  fontWeight: 700,
                  transform: 'translateX(40%)',
                },
                '.MuiStep-root': {
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              }}
              connector={<CustomStepperConnector />}
            >
              {steps.map((step, key) => (
                <Step key={key} completed={key < activeStep}>
                  <StyledStepLabel>{isMobileView ? '' : step.title}</StyledStepLabel>
                </Step>
              ))}
            </Stepper>
          )}
          <Breadcrumbs aria-label='breadcrumbs' sx={{ color: redesignColors.dark, position: 'absolute', left: '32px', bottom: { md: '-40px' }, zIndex: 1 }}>
            <Typography sx={{ cursor: 'pointer' }} onClick={() => navigate(RoutePaths.HOME)}>
              {t('new_appointment.breadcrumbs.home')}
            </Typography>
            <Typography sx={{ color: redesignColors.grey.grey2 }}>{t('landing_page.card.fertility.title')}</Typography>,
          </Breadcrumbs>
        </Grid>

        {isOpeningPage && !isAuthenticated && (
          <>
            <ConnectAccountDialog fullWidth redirect={RoutePaths['HOME']} sx={{ display: 'flex', justifyContent: 'center' }} cardSx={{ width: { xs: '100%', md: '440px !important' } }} />
          </>
        )}
        {isOpeningPage &&
          (showInfoPage ? (
            <>
              {!isMobileView && <Grid item md={3} />}
              <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', height: '70vh' }}>
                <Box sx={{ width: { xs: '100%', md: '440px !important' } }}>
                  <Alert severity='info' sx={{ backgroundColor: redesignColors.blue.blue3, fontSize: '14px', fontWeight: 500, borderRadius: '8px' }} icon={<SvgIcon component={InfoIcon} />}>
                    {t('fertility.opening.male_only')}
                  </Alert>
                  <Typography fontSize='18px' fontWeight='700' py={2}>
                    {t('fertility.opening.what_is')}
                  </Typography>
                  <Typography variant='body2'>{t('fertility.opening.what_is_description')}</Typography>
                </Box>
                <Button
                  variant='contained'
                  sx={{ display: 'block' }}
                  onClick={() => {
                    setShowInfoPage(false);
                  }}
                >
                  {t('fertility.opening.book_button')}
                </Button>
              </Grid>
              {!isMobileView && <Grid item md={3} />}
            </>
          ) : (
            <SelectFlowStep
              handleNext={(type) => {
                setFertilityType(type);
                setIsOpeningPage(false);
              }}
            />
          ))}

        {!isOpeningPage && (
          <>
            <Grid item xs={12} container position='relative' display='flex' flexDirection='column'>
              {activeStep !== 0 && (
                <Button
                  sx={{ color: redesignColors.red, position: 'absolute', right: 0, top: '16px' }}
                  startIcon={<CloseIcon sx={{ fill: redesignColors.red }} />}
                  onClick={() => setOpenDialogType(DialogType.delete)}
                >
                  {t('fertility.opening.cancel_button')}
                </Button>
              )}
              {steps[activeStep].component}
            </Grid>
          </>
        )}

        {!showInfoPage && (
          <Grid item {...nextAndBackContainerProps}>
            <Box display='flex' justifyContent='space-between' sx={isOpeningPage ? { mt: '45vh' } : {}}>
              {!showInfoPage && (
                <Button
                  variant='outlined'
                  sx={{ width: isMobileView ? 150 : 219 }}
                  onClick={() => {
                    if (isOpeningPage) {
                      setShowInfoPage(true);
                      return;
                    }
                    if (activeStep === 0) {
                      setIsOpeningPage(true);
                      return;
                    }
                    setActiveStep((prev) => prev - 1);
                    setIsActiveNextButton(true);
                    if (showSlots) {
                      setShowSlots(false);
                    }
                  }}
                >
                  {isAuthenticated && (isOpeningPage || activeStep === 0) ? t('common.action.back') : t('fertility.back_button')}
                </Button>
              )}
              {!isOpeningPage && (
                <Button
                  variant='contained'
                  disabled={isLastStep ? false : activeStep === steps.length - 1 || !isActiveNextButton}
                  sx={{ width: isMobileView ? 150 : 219 }}
                  onClick={() => {
                    if (isLastStep) {
                      handleCreateAppointment();
                      return;
                    }

                    if (steps[activeStep].type === FertilityStepType.availableSlot && fertilityType === AnalysisType.test && date && !showSlots) {
                      setShowSlots(true);
                      setIsActiveNextButton(false);
                      return;
                    }
                    setActiveStep((activeStep) => (activeStep >= steps.length - 1 ? activeStep : activeStep + 1));
                    setIsActiveNextButton(false);
                    if (showSlots) {
                      setShowSlots(false);
                    }
                  }}
                >
                  {t(steps[activeStep]?.nextButtonLabel || 'common.action.next')}
                </Button>
              )}
            </Box>
          </Grid>
        )}
        {openDialogType && (
          <ConfirmDialog
            open
            title={t(dialogs[openDialogType].title)}
            subtitle={t(dialogs[openDialogType].subtitle)}
            submitTitle={t(dialogs[openDialogType].submitTitle)}
            cancelTitle={t(dialogs[openDialogType]?.cancelTitle || '')}
            hideCancel={dialogs[openDialogType]?.hideCancel}
            onClose={() => {
              setOpenDialogType(null);
            }}
            onCloseButtonClick={() => {
              if (openDialogType === DialogType.not_available_slot) {
                navigate(RoutePaths.HOME);
              }
            }}
            onSubmit={() => {
              if (openDialogType === DialogType.not_available_slot) {
                const slotStepIndex = steps.findIndex((step) => step.type === FertilityStepType.availableSlot);

                if (!slotStepIndex) {
                  return;
                }
                setActiveStep(slotStepIndex);
                return;
              }
              navigate(RoutePaths.HOME);
            }}
          />
        )}
      </Grid>
    </BodyTemplate>
  );
};

export default Fertility;
