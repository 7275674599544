import api from 'api';
import { RdvCenter } from 'models/appointment.model';
import { CenterInfo } from 'pages/patient/centers/types';
import { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { FertilityCenterActionType } from 'store/actions/fertilityCenter.actions';

type ReturnParams = {
  center: CenterInfo | null;
  rdvCenter: RdvCenter | null;
  isLoading: boolean;
};

export const centerID = '70c803c1-d231-4701-abec-a4261b18d970';

export const useFertilityCenter = (): ReturnParams => {
  const { getState, dispatch } = useStore();

  const [center, setCenter] = useState<CenterInfo | null>(null);
  const [rdvCenter, setRdvCenter] = useState<RdvCenter | null>(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    const { center: fertilityCenter, rdvCenter } = getState().fertilityCenter;

    if (!fertilityCenter) {
      Promise.all([api.bnl.getCenter(centerID), api.appointments.getCenters()])
        .then(([myLabCenter, rdvCenters]) => {
          const newRdvCenter = rdvCenters[0];
          dispatch({ type: FertilityCenterActionType.ADD, center: myLabCenter, rdvCenter: newRdvCenter });
          setCenter(myLabCenter);
          setRdvCenter(newRdvCenter);
        })
        .finally(() => setLoading(false));
    } else {
      setCenter(fertilityCenter);
      setRdvCenter(rdvCenter);
      setLoading(false);
    }
  }, []);

  return { center, rdvCenter, isLoading };
};
