import { Female, Male } from '@mui/icons-material';
import { Card, CardContent, Divider, Grid, Stack, styled, TextField, TextFieldProps, ToggleButton, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AppointmentPatient } from 'components/appointment/types/Patient';
import PhoneInput from 'components/phone-input';
import { Gender } from 'models/profile.model';
import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { redesignColors } from 'resources/theme/theme.colors';
import { NewAppointmentFlowActionType } from 'store/actions/newAppointmentFlow.actions';
import { PatientInfo } from 'store/reducers/newAppointmentFlow.reducer';

interface PatientInfoCardProps {
  customId?: string;
  type: 'Adult' | 'Child' | 'Patient';
  onlyMale?: boolean;
  disableStorage?: boolean;
  values?: AppointmentPatient;
  setValue?: (key: string, value: string | Date) => void;
  setNextButtonActive?: (state: boolean) => void;
}

const StyledGenderButton = styled(ToggleButton)(({ theme, selected }) => ({
  width: '100%',
  borderRadius: '8px',
  border: '0.5px solid grey',
  paddingLeft: '5px',
  justifyContent: 'space-around',
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: 'black',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  '.genderIcon': {
    '&.male': {
      color: selected ? 'black' : theme.palette.primary.main,
    },
    '&.female': {
      color: selected ? 'black' : 'pink',
    },
  },
}));

const StyledTextField = styled((props: TextFieldProps) => <TextField {...props} variant='filled' />)(({ theme }) => ({
  width: '100%',
  borderRadius: '8px',
  '& .MuiInputBase-root': {
    borderRadius: '8px',
  },
  '.MuiFormLabel-root': {
    padding: 0,
  },
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  width: '100%',
  backgroundColor: '#F2FAFD',
  borderRadius: '8px',
  '& .MuiInputBase-root': {
    borderRadius: '8px',
  },
  '.MuiFormLabel-root': {
    padding: 0,
  },
}));

export const isValidEmail = (value: string) => {
  const emailPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return emailPattern.test(value);
};

export const getEmailHelperText = (email: string): string | false => {
  if (email && !isValidEmail(email)) {
    return 'fertility.errors.invalid_email';
  }
  return false;
};
export const getConfirmEmailHelperText = (email: string, confirmEmail: string): string | false => {
  if (confirmEmail && !isValidEmail(confirmEmail)) {
    return 'fertility.errors.invalid_email';
  }
  if (email && confirmEmail && email !== confirmEmail) {
    return 'fertility.errors.emails_not_match';
  }
  return false;
};

const PatientInfoCard: FC<PatientInfoCardProps> = ({ customId, type, onlyMale, values, setValue, setNextButtonActive = (state: boolean) => {}, disableStorage }) => {
  const { t } = useTranslation();
  const { getState, dispatch } = useStore();

  const [firstName, setFirstName] = useState(values?.firstName || '');
  const [lastName, setLastName] = useState(values?.lastName || '');
  const [birthName, setBirthName] = useState(values?.birthName || '');
  const [gender, setGender] = useState<Gender>((values?.gender as Gender) || Gender.MALE);
  const [email, setEmail] = useState(values?.email || '');
  const [emailConfirm, setEmailConfirm] = useState(values?.emailConfirm || '');
  const [phone, setPhone] = useState(values?.phone || '');
  const [birthDate, setBirthDate] = useState<Date | null>(values?.birthDate instanceof Date ? values?.birthDate : null);
  const [cns, setCns] = useState(values?.cns || '');
  const [canRemove, setCanRemove] = useState(values?.canRemove || false);

  useEffect(() => {
    const storage = getState().newAppointmentFlowReducer;

    if (!storage || disableStorage) return;
    if (storage.patients) {
      const patient = storage.patients.find((p: PatientInfo) => p.id === customId);
      console.log('patient', patient);
      if (patient) {
        if (patient.firstName) setFirstName(patient.firstName);
        if (patient.lastName) setLastName(patient.lastName);
        if (patient.birthName) setBirthName(patient.birthName);
        if (patient.birthDate) setBirthDate(patient.birthDate instanceof Date ? patient.birthDate : null);
        if (patient.cns) setCns(patient.cns || '');
        if (patient.phone) setPhone(patient.phone);
        if (patient.email) setEmail(patient.email);
        if (patient.emailConfirm) setEmailConfirm(patient.emailConfirm);
        if (patient.gender) setGender(patient.gender);
        if (patient.canRemove) setCanRemove(patient.canRemove);
      }
    }
  }, [customId, disableStorage, getState, setFirstName, setLastName, setBirthDate, setBirthName, setCns, setPhone, setEmail, setEmailConfirm, setGender]);

  useEffect(() => {
    const isValidEmails = email === emailConfirm && isValidEmail(email) && isValidEmail(emailConfirm);
    if (!firstName || !birthName || !email || !emailConfirm || !phone || !isValidEmails) {
      setNextButtonActive(false);
    } else {
      setNextButtonActive(true);

      dispatch({
        type: NewAppointmentFlowActionType.SET_PATIENT_INFO,
        patientInfo: { id: customId, firstName, lastName, birthName, birthDate, email, emailConfirm, phone, gender, cns, canRemove, type, selected: true },
      });
    }
  }, [firstName, lastName, birthName, gender, email, emailConfirm, phone, birthDate, cns, customId, getState, dispatch, setNextButtonActive, canRemove, type]);

  useEffect(() => {
    if (values) {
      setFirstName(values.firstName);
      setLastName(values.lastName || '');
      setBirthName(values?.birthName || '');
      setGender(values?.gender as Gender);
      setEmail(values.email);
      setEmailConfirm(values?.emailConfirm || '');
      setPhone(values.phone);
      setBirthDate(values.birthDate instanceof Date ? values.birthDate : null);
      setCns(values.cns || '');
    }
  }, [setFirstName, setLastName, setBirthName, setGender, setEmail, setEmailConfirm, setPhone, setBirthDate, setCns, values]);

  const emailHelperText = getEmailHelperText(email);
  const emailConfirmHelperText = getConfirmEmailHelperText(email, emailConfirm);

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography sx={{ fontWeight: 600 }} variant='h6'>
              {type}
            </Typography>
            <Divider />
          </Stack>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <Grid container spacing={1}>
              <Grid item xs={6} md={5} display='flex'>
                <StyledGenderButton
                  selected={gender === Gender.MALE}
                  value={Gender.MALE}
                  onChange={(e) => {
                    setGender(Gender.MALE);
                    setValue?.('gender', Gender.MALE);
                  }}
                  sx={{ mr: 2 }}
                >
                  <Male className='genderIcon male' /> {t('new_appointment.stepper.steps.information.male')}
                </StyledGenderButton>
                <StyledGenderButton
                  selected={gender === Gender.FEMALE}
                  value={Gender.FEMALE}
                  disabled={onlyMale}
                  sx={{ ...(onlyMale && { background: redesignColors.grey.grey4, color: redesignColors.grey.grey2 }) }}
                  onChange={(e) => {
                    setGender(Gender.FEMALE);
                    setValue?.('gender', Gender.FEMALE);
                  }}
                >
                  <Female className='genderIcon male' sx={{ ...(onlyMale && { color: `${redesignColors.grey.grey2} !important` }) }} /> {t('new_appointment.stepper.steps.information.female')}
                </StyledGenderButton>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledTextField
                  required
                  type='text'
                  label={t('new_appointment.stepper.steps.information.first_name')}
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setValue?.('firstName', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledTextField
                  required
                  type='text'
                  label={t('new_appointment.stepper.steps.information.birth_name')}
                  value={birthName}
                  onChange={(e) => {
                    setBirthName(e.target.value);
                    setValue?.('birthName', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledTextField
                  type='text'
                  label={t('new_appointment.stepper.steps.information.last_name')}
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setValue?.('lastName', e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledDatePicker
                  label={t('new_appointment.stepper.steps.information.birth_date') + '*'}
                  value={birthDate}
                  defaultValue={null}
                  onChange={(val) => {
                    setBirthDate(val as Date);
                    setValue?.('birthDate', val as Date);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledTextField
                  type='text'
                  label={t('new_appointment.stepper.steps.information.cns')}
                  value={cns}
                  onChange={(e) => {
                    setCns(e.target.value);
                    setValue?.('cns', e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledTextField
                  required
                  type='email'
                  error={!!email && !isValidEmail(email)}
                  helperText={emailHelperText ? t(emailHelperText) : false}
                  label={t('new_appointment.stepper.steps.information.email')}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setValue?.('email', e.target.value);
                  }}
                />
                <Typography sx={{ color: '#7A9099', fontSize: '14px', lineHeight: '100%' }} variant='button'>
                  {t('new_appointment.stepper.steps.information.email_info')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <StyledTextField
                  required
                  type='email'
                  error={!!emailConfirm && (!isValidEmail(emailConfirm) || emailConfirm !== email)}
                  helperText={emailConfirmHelperText ? t(emailConfirmHelperText) : false}
                  label={t('new_appointment.stepper.steps.information.confirm_email')}
                  value={emailConfirm}
                  onChange={(e) => {
                    setEmailConfirm(e.target.value);
                    setValue?.('emailConfirm', e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <PhoneInput
                  sx={{
                    width: '100%',
                    backgroundColor: '#F2FAFD',
                    borderRadius: '8px',
                    '& .MuiInputBase-root': {
                      borderRadius: '8px',
                      padding: '5px 16px',
                    },
                    '.MuiFormLabel-root': {
                      padding: 0,
                    },
                  }}
                  label={t('new_appointment.stepper.steps.information.phone')}
                  required
                  value={phone}
                  onChange={(e) => {
                    setPhone(e as string);
                    setValue?.('phone', e as string);
                  }}
                />
                <Typography sx={{ color: '#7A9099', fontSize: '14px', lineHeight: '100%' }} variant='button'>
                  {t('new_appointment.stepper.steps.information.phone_info')}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PatientInfoCard;
